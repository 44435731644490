@media (max-width: 900px){
    .items, .about, .cart{
        width: 100%;
        margin-left: 0%;
    }
    .item-profile{
        width: 50vw;
        margin: 0%;
        margin-bottom: 2vh;
    }
    .cart-footer{
        background-color: blanchedalmond;
    }
    img{
        height: 35vh;
        width: 25vw;
    }
    .step1-inputs, .step2-inputs{
        background-color: blanchedalmond;
    }
    .title{
        font-size: 5vw;
    }
    .login_register2{
        flex-direction: column;
        font-size: 2vh;
    }
    button:hover{
        background-color: rgba(0, 255, 255, 0.788);
        font-size: 2vh;
    }
    .admin-elements:hover{
        border: 2px solid black;
        background-color: beige;
    }
    img:hover{
        height: 40vh;
        width: 30vw;
        position: relative;
        z-index: 1;
    }
    .order-list:hover{
        cursor: pointer;
        border: 1px solid black;
    }
    button{
        background-color: rgba(0, 255, 255, 0.788);
            font-size: 2vh;
    }
    .app{
            width: 0vw;
    }
    .about{
            font-size: 2vh;
    }
    .login_register2{
        flex-direction: column;
        font-size: 2vh;
        height: 110vh;
        }
}

@media (max-width: 500px){
    .items, .about, .cart{
        width: 100%;
        margin-left: 0%;
    }
    .user-cart{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .customer-profile{
        font-size: 3.25vw;
    }
    .item-profile{
        width: 100%;
        margin: 0%;
        margin-bottom: 2vh;
    }
    .cart-footer{
        background-color: blanchedalmond;
    }
    img{
        height: 40vh;
        width: 60vw;
    }
    .step1-inputs, .step2-inputs{
        background-color: blanchedalmond;
    }
    .title{
        font-size: 5vw;
    }
    .login_register2{
    flex-direction: column;
    font-size: 2vh;
    height: 130vh;
    }
    button{
        background-color: rgba(0, 255, 255, 0.788);
        font-size: 2vh;
    }
    .app{
        width: 0vw;
    }
    .about{
        font-size: 2vh;
    }
    button:hover{
        background-color: rgba(0, 255, 255, 0.788);
        font-size: 2vh;
        cursor: pointer;
    }
    .admin-elements:hover{
        border: 2px solid black;
        background-color: beige;
    }
    img:hover{
        height: 40vh;
        width: 30vw;
        position: relative;
        z-index: 1;
    }
    .order-list:hover{
        cursor: pointer;
        border: 1px solid black;
    }
    .welcome{
        width: 10vw;
        font-size: 3.7vw;
        padding-right: 3vw;
    }
    .welcome-button{
        font-size: 2.5vw;
        margin-right: .25vw;
    }
    .names-user, .email-subbed{
        font-size: 4.5vw;
    }
    .order{
        width: 70%;
        font-size: 5.5vw;
    }
}