/* universal styles */
.App {
  text-align: center;
  background-color: black;
  min-height: 100vh;
}
h1 {
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-family: 'Lobster', cursive;
  font-size: 3vh;
  text-align: center;
}
nav{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 10vh;
}
button, .stripe{
  font-family: 'Lobster', cursive;
  font-size: 2.5vh;
  border-radius: 12px;
  background-color: transparent;
  background-color: rgba(0, 255, 255, 0.788);
  border: 2px solid black;
  outline: none;
}
p{
  padding-top: 1.5vh;
}
img{
  height: 30vh;
  width: 20vw;
}
ul, li{
  list-style-type: none;
}
.items, .cart-items{
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  color: whitesmoke;
}
.item-profile, .user-cart{
  padding-top: 2vh;
  padding-bottom: 2vh;
  width: 30vw;
  min-height: 40vh;
  margin: auto;
  margin-bottom: 2vh;
  font-family: 'Cinzel', serif;
  font-size: 2vh;
}
.product-name{
  font-size: 3vh;
}
.city-state-zip{
  display: flex;
  justify-content: center
}
.step1, .step2, .step3, .cart-total, .newsletter, .profile{
  color: rgba(0, 255, 255, 0.788);
}

/* hover styles */
button:hover{
  /* background-color: whitesmoke; */
  font-size: 3.5vh;
  cursor: pointer;
}
img:hover{
  height: 40vh;
  width: 30vw;
  position: relative;
  z-index: 1;
}
.admin-elements:hover{
  border: 2px solid rgba(0, 255, 255, 0.788);
  background-color: slategray;
  color: black;
}

.order-list:hover{
  cursor: pointer;
  border: 1px solid black;
}